<template>
  <b-row>
    <b-col cols="12">
      <b-card title="All Due Diligences">

        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search"
                type="text"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :to="{name: 'add-duediligences'}"
                class="d-inline-flex mr-1"
                variant="outline-success"
              >
                <feather-icon
                  class="mr-50"
                  icon="PlusIcon"
                />
                <span class="align-middle text-nowrap">Add DueDiligence</span>
              </b-button>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <b-overlay
          :show="$apollo.loading"
          spinner-variant="primary"
        >
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Total Stages -->
              <span
                v-if="props.column.field === 'total_stages'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.duediligence_stagetables.length }}</span>
              </span>

              <!-- Column: Total Stages -->
              <span
                v-else-if="props.column.field === 'partners'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.duediligence_partnertables.length }}</span>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    dropright
                    no-caret
                    size="sm"
                    toggle-class="text-decoration-none"
                    variant="link"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        class="text-body align-middle"
                        icon="MoreVerticalIcon"
                        size="16"
                      />
                    </template>
                    <b-dropdown-item :to="`/duediligence/manager/${props.row.id}`">
                      <feather-icon
                        class="mr-50"
                        icon="CheckSquareIcon"
                      />
                      <span>Due Diligence Manager</span>
                    </b-dropdown-item>
                    <b-dropdown-item :to="`/duediligence/add/${props.row.id}`">
                      <feather-icon
                        class="mr-50"
                        icon="Edit2Icon"
                      />
                      Edit Purpose
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon
                        class="mr-50"
                        icon="EyeIcon"
                      />
                      <span>View Details</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="danger"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="TrashIcon"
                      />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Purpose',
          field: 'purpose',
        },
        {
          label: 'Total Stages',
          field: 'total_stages',
        },
        {
          label: 'Partners',
          field: 'partners',
        },
        {
          label: 'Total Startups',
          field(row) {
            if (row) return '-'
            return '-'
          },
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  apollo: {
    rows: {
      query: gql`
        {
          duediligence_basicinfo {
            purpose
            id
            duediligence_stagetables {
              title
              description
              id
            }
            duediligence_partnertables {
              id
            }
          }
        }
      `,
      update: data => data.duediligence_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
